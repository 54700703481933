<template>
    <div style="height:80px;background-color: lightblue; margin: 5px 10px 0px 5px">
        <div class="qsupersmallfont" style="padding-top:5px;" ><span>OG Bets</span>  <span>{{langConv('Summary Stats for')}}</span> <span>{{langConv($displayDate(Date(),$dateformattype.monthonly))}}</span></div>
        <carousel ref="carousel"  :paginationEnabled="false" :perPage="1" :navigationEnabled="false" :autoplay="true" :autoplayHoverPause="true" :loop="true" :autoplayTimeout="4000" :speed="100">

            <template v-for="(row,i) in summarystatsdata" >
                    <slide style='color:black;padding:6px;height:50px' :key=i v-if="row.value!=null">
                       
                            <div class="grid-x qmediumboldfont" style="height:24px">
                                <div class="cell small-6 text-center" style="padding-top:4px">{{ langConv(row.description) }}</div>
                                <div class="cell small-3" style="background-color: white; padding: 5px;border-radius: 5px;margin-top:-4px; border:3px solid powderblue">{{ row.previousvalue }}</div>
                                <div class="cell small-3"  style="background-color: white; padding: 5px;border-radius: 5px;margin-top:-4px; border:3px solid powderblue">
                                    <div class="grid-x">
                                        <div class="cell">{{ row.value }}</div>
                                        <div class="cell" style="margin-top:-2px"  :class="[{up: row.previousvalue < row.value, down: row.previousvalue > row.value}]"></div>
                                   </div>  
                                </div> 
                            </div>
                            <div class="grid-x qxsupersmallfont" >
                                <div class="cell small-6" ></div>
                                <div class="cell small-3" style="margin-top:9px;padding-bottom: 5px;" v-html="langConv('Last Month')"></div>
                                <div class="cell small-3" style="margin-top:9px;padding-bottom: 5px;" v-html="langConv('This Month')"></div>
                            </div>
                      
                    </slide>
            </template>

        </carousel>
    </div>
</template>

<script>
 import { Carousel, Slide } from 'vue-carousel';

    export default {
        name: 'summarystats',
        data() {
            return {
                summarystatsdata: []
            }
        },
        created () {
            this.GetData();
        },

        methods: {
            GetData() {
                this.localJSONLoad(process.env.VUE_APP_DATA + "ogbetstats.json").then(response => {
                
                    this.summarystatsdata= response;  
                   

                }).catch((error) => {
                    console.log(error);
                })
                
            }
        },
        components: {
            Carousel,
            Slide
        },
}
</script>

<style lang="scss" scoped>
    .up {
        margin-left: auto;
        margin-right: auto;
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid green;
    }
    .down {
        margin-left: auto;
        margin-right: auto;
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid red;
    }
    .same {
        color: black
    }
</style>